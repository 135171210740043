import {LoginDto, SignUpDto, checkDuplicateUserIdDto, NaverLoginDto} from '@/services/LoginModels';
import AXIOS from '@/services/axios-wrapper';
import {User, UserIdFind} from '@/models';

class LoginService {
  constructor() {
    console.log('creating new instance of LoginService');
  }

  guestLogin() {
    return AXIOS.post(`guestLogin`);
  }

  login(loginDto: LoginDto) {
    return AXIOS.post(`login`, loginDto);
  }

  logout() {
    return AXIOS.post(`logout`);
  }

  refreshToken() {
    return AXIOS.post(`userRefreshToken`);
  }

  checkDuplicateId(userIdDto: checkDuplicateUserIdDto) {
    return AXIOS.post(`checkDuplicateId`, userIdDto);
  }

  userJoin(signUpDto: SignUpDto) {
    return AXIOS.post(`userJoin`, signUpDto);
  }

  naverLogin(naverLoginDto: NaverLoginDto) {
    return AXIOS.post(`naverLogin`, naverLoginDto);
  }

  kakaoLogin(kakaoLoginDto: NaverLoginDto) {
    return AXIOS.post(`kakaoLogin`, kakaoLoginDto);
  }

  getBizSirenEncode() {
    return AXIOS.get(`getBizSirenEncode`);
  }

  getBizSirenPhoneDecode(type: string, retInfo: string) {
    return AXIOS.get(`getBizSirenPhoneDecode/` + type + `/` + retInfo);
  }

  getBizSirenEncodeIpin() {
    return AXIOS.get(`getBizSirenEncodeIpin`);
  }

  getBizSirenIpinDecode(retInfo: string) {
    return AXIOS.get(`getBizSirenIpinDecode/` + retInfo);
  }

  userIdFind(user: User) {
    return AXIOS.post(`userIdFind`, user);
  }

  userIdFindMissing(user: UserIdFind) {
    return AXIOS.post(`userIdFindMissing`, user);
  }

  userLeave(user: User, userId: string) {
    return AXIOS.post(`userLeave/` + userId, user);
  }

  userMyInfo() {
    return AXIOS.post(`MyInfo`);
  }

  userPWCheck(user: User, userPassword: string) {
    return AXIOS.post(`PWCheck/` + userPassword, user);
  }

  userMyInfoChange(user: User, newUserInfo: SignUpDto) {
    const url = `MyInfoChange`;
    return AXIOS.post(url, newUserInfo);
  }

  userPwChange(user: User, oldPassword: string, newPassword: string) {
    const url = `UserPwChange/` + oldPassword + '/' + newPassword;
    return AXIOS.post(url, user);
  }

  userPwChangeNologin(userId: string, retInfo: string, newPassword: string) {
    const url = `UserPwChangeNologin/` + newPassword + '/' + retInfo + '/' + userId;
    return AXIOS.get(url);
  }

  snsUserLeave(userId: string, snsId: string) {
    const url = `SnsUserLeave/` + userId + '/' + snsId;
    return AXIOS.post(url);
  }

  sendEmailAuthCode(toEmail: string) {
    return AXIOS.get(`SendEmailAuthCode/` + toEmail);
  }
  checkEmailAuthCode(authCode: string) {
    return AXIOS.get(`CheckEmailAuthCode/` + authCode);
  }
}

// Export a singleton instance in the global namespace
export const loginService = new LoginService();
