<template lang="html">
  <ValidationProvider rules="required|min:1" v-slot="{errors}" slim>
    <b-form-input
      autocomplete="off"
      :state="errors.length == 0"
      v-model="propValInternal"
      :type="type"
      :readonly="readonly"
      :placeholder="placeholder"
      @paste="removeEventSpaces"
      @drop="removeEventSpaces"
      :formatter="noSpaceFomatter"
    ></b-form-input>
    <!-- <b-form-invalid-feedback :state="errors.length == 0">필수 입력 필요</b-form-invalid-feedback> -->
  </ValidationProvider>
</template>

<script>
import {noSpaceFomatter} from '@/util/GeneralUtil';
export default {
  name: 'FormRequiredInput',
  props: {
    value: {
      required: true,
      default: null,
    },
    type: String,
    readonly: Boolean,
    placeholder: {
      type: [Number, String],
    },
  },
  data() {
    return {
      propValInternal: '',
    };
  },
  watch: {
    propValInternal(newVal) {
      console.log('propValInternal', newVal.replace(/ +/g, ''));
      this.$emit('input', newVal.replace(/ +/g, ''));
    },
    value(newVal) {
      console.log('value1', newVal.replace(/ +/g, ''));
      this.propValInternal = newVal.replace(/ +/g, '');
      console.log('value2', this.propValInternal);
    },
  },
  methods: {
    noSpaceFomatter: noSpaceFomatter,
    // input(value) {
    //   console.log(`input:${value}`);
    //   this.$emit('input', value.replace(/ +/g, ''));
    // },
    removeEventSpaces(e) {
      e.preventDefault();
      const left = e.target.value.substring(0, e.target.selectionStart);
      const right = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
      const pasted = (e.dataTransfer || e.clipboardData).getData('text').replace(/ /g, '');
      e.target.value = left + pasted + right;
    },
  },
};
</script>
