<template>
  <div>
    <div class="header">
      <h1 class="overlay">INQUIRY</h1>
      <div class="headerimage" />
    </div>

    <div class="container contentDesc">
      <div class="title1">
        <h2>
          <strong>마케팅 제휴 문의</strong>
        </h2>
      </div>
    </div>

    <div class="container">
      <div class="page-content">
        <div class="in-content-center">
          <article class="contact-detail">
            <div class="box-gray">
              <div class="txt-top">문의하신 내용은 빠른 시간 내에 연락처 또는 이메일로 답변을 드리겠습니다.</div>

              <table class="webTable table-join-form table-contact">
                <tbody>
                  <tr>
                    <th>제휴사</th>
                    <td>
                      <b-form-input autocomplete="off" type="text" class="input-text it-medium" v-model="affiliate" />
                    </td>
                  </tr>
                  <tr>
                    <th>담당자/직책</th>
                    <td>
                      <b-form-input autocomplete="off" type="text" class="input-text it-medium" v-model="manager" />
                    </td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                    <td>
                      <ValidationProvider rules="required|email" name="Email" v-slot="{errors}">
                        <b-input-group>
                          <b-form-input
                            autocomplete="off"
                            :state="errors.length === 0"
                            required
                            type="email"
                            v-model="userEmail"
                            :disabled="sendEmailAuth || chkEmailAuth"
                          />
                          <b-input-group-append v-if="sendEmailAuth === false">
                            <b-button class="py-0" variant="primary" :disabled="sendingEmail" @click="sendEmailAuthCode"
                              >인증번호 받기
                            </b-button>
                          </b-input-group-append>
                          <b-input-group-append v-if="sendEmailAuth === true">
                            <b-button class="py-0" variant="primary" @click="resetEmail">이메일 재입력</b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback :state="errors.length === 0"
                          >이메일 형식에 맞게 입력해주세요
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <th>이메일 인증</th>
                    <td>
                      <b-input-group>
                        <b-form-input autocomplete="off" v-model="emailAuthCode" />
                        <b-input-group-append>
                          <b-button class="py-0" variant="primary" @click="checkEmailAuthCode" :disabled="chkEmailAuth"
                            >인증
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                  <tr>
                    <th>연락처</th>
                    <td>
                      <ValidationProvider
                        :rules="{required: true, regex: /^[0-9]{10,11}$/}"
                        name="userPhone"
                        v-slot="{errors}"
                      >
                        <FormRequiredInput v-model="userPhone"></FormRequiredInput>
                        <b-form-invalid-feedback :state="errors.length === 0"
                          >연락처 형식에 맞게 입력해주세요
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <th>행사명/기업명</th>
                    <td>
                      <b-form-input autocomplete="off" type="text" class="input-text it-medium" v-model="userCompany" />
                    </td>
                  </tr>
                  <tr>
                    <th>희망제휴업장</th>
                    <td class="hope-company">
                      <div class="grid-container gutter-10">
                        <b-form-select
                          class="rounded-0 icon-chevron-down"
                          v-model="wantedCompany"
                          :options="wantedCompanyOptions"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>협찬 및 제안 내용</th>
                    <td>
                      <textarea class="textarea t-medium" rows="7" placeholder="" v-model="desc"></textarea>
                    </td>
                  </tr>
                  <tr>
                    <th>파일첨부</th>
                    <td colspan="3">
                      <div class="custom-file">
                        <b-form-file
                          v-model="attachedFile"
                          placeholder="파일을 등록해주세요"
                          browse-text="찾아보기"
                          accept=".jpg, .png, .gif, .zip, .pdf, .ppt, .hwp, .doc, .docx, .pptx"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td colspan="2" class="table-footer" height="120px">
                      <a href="#" @click="sendMail" class="button b-black block">문의하기</a>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table class="mobTable table-join-form table-contact">
                <tbody>
                  <tr>
                    <th>제휴사</th>
                  </tr>
                  <tr>
                    <td>
                      <b-form-input autocomplete="off" type="text" class="input-text it-medium" v-model="affiliate" />
                    </td>
                  </tr>
                  <tr>
                    <th>담당자/직책</th>
                  </tr>
                  <tr>
                    <td>
                      <b-form-input autocomplete="off" type="text" class="input-text it-medium" v-model="manager" />
                    </td>
                  </tr>
                  <tr>
                    <th>이메일</th>
                  </tr>
                  <tr>
                    <td>
                      <ValidationProvider rules="required|email" name="Email" v-slot="{errors}">
                        <b-input-group>
                          <b-form-input
                            autocomplete="off"
                            :state="errors.length === 0"
                            required
                            type="email"
                            v-model="userEmail"
                            :disabled="sendEmailAuth || chkEmailAuth"
                          />
                          <b-input-group-append v-if="sendEmailAuth === false">
                            <b-button class="py-0" variant="primary" :disabled="sendingEmail" @click="sendEmailAuthCode"
                              >인증번호 받기
                            </b-button>
                          </b-input-group-append>
                          <b-input-group-append v-if="sendEmailAuth === true">
                            <b-button class="py-0" variant="primary" @click="resetEmail">이메일 재입력</b-button>
                          </b-input-group-append>
                        </b-input-group>
                        <b-form-invalid-feedback :state="errors.length === 0"
                          >이메일 형식에 맞게 입력해주세요
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <th>이메일 인증</th>
                  </tr>
                  <tr>
                    <td>
                      <b-input-group>
                        <b-form-input autocomplete="off" v-model="emailAuthCode" />
                        <b-input-group-append>
                          <b-button class="py-0" variant="primary" @click="checkEmailAuthCode" :disabled="chkEmailAuth"
                            >인증
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                  <tr>
                    <th>연락처</th>
                  </tr>
                  <tr>
                    <td>
                      <ValidationProvider
                        :rules="{required: true, regex: /^[0-9]{10,11}$/}"
                        name="userPhone"
                        v-slot="{errors}"
                      >
                        <FormRequiredInput v-model="userPhone"></FormRequiredInput>
                        <b-form-invalid-feedback :state="errors.length === 0"
                          >연락처 형식에 맞게 입력해주세요
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </td>
                  </tr>
                  <tr>
                    <th>행사명/기업명</th>
                  </tr>
                  <tr>
                    <td>
                      <b-form-input autocomplete="off" type="text" class="input-text it-medium" v-model="userCompany" />
                    </td>
                  </tr>
                  <tr>
                    <th>희망제휴업장</th>
                  </tr>
                  <tr>
                    <td class="hope-company">
                      <div class="grid-container gutter-10">
                        <b-form-select
                          class="rounded-0 icon-chevron-down"
                          v-model="wantedCompany"
                          :options="wantedCompanyOptions"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>협찬 및 제안 내용</th>
                  </tr>
                  <tr>
                    <td>
                      <textarea class="textarea t-medium" rows="7" placeholder="" v-model="desc"></textarea>
                    </td>
                  </tr>
                  <tr>
                    <th>파일첨부</th>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <div class="custom-file">
                        <b-form-file
                          v-model="attachedFile"
                          placeholder="파일을 등록해주세요"
                          browse-text="찾아보기"
                          accept=".jpg, .png, .gif, .zip, .pdf, .ppt, .hwp, .doc, .docx, .pptx"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-footer" height="120px">
                      <div @click="sendMail" class="inquiry_button">
                        <span>문의하기</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--/.box-gray-->
          </article>
        </div>
        <!--/.in-content-center-->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import FormRequiredInput from '@/components/forms/FormRequiredInput';
import {ValidationObserver} from 'vee-validate';
import FormTel from '@/components/forms/FormTel';
import {loginService} from '@/services/login.service';
import GeneralUtil from '@/util/GeneralUtil';

export default {
  name: 'Marketingcontact',
  components: {FormRequiredInput},
  mixins: [GeneralUtil],
  data() {
    return {
      affiliate: '',
      manager: '',
      userEmail: '',
      userPhone: '',
      userCompany: '',
      wantedCompany: '',
      wantedCompanyOptions: ['골프클럽', '포도호텔', '디아넥스'],
      desc: '',
      attachedFile: null,
      chkEmailAuth: false,
      emailAuthCode: '',
      sendingEmail: false,
      sendEmailAuth: false,
    };
  },
  methods: {
    async checkEmailAuthCode() {
      if (this.userEmail === '') {
        this.$bvModal.msgBoxOk('이메일주소를 입력하세요', {
          title: '이메일주소 입력필요',
          okVariant: 'danger',
          centered: true,
        });
        return;
      }
      if (this.emailAuthCode === '') {
        this.$bvModal.msgBoxOk('인증코드를 입력하세요', {
          title: '인증코드입력필요',
          okVariant: 'danger',
          centered: true,
        });
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      const popupTitle = '이메일 인증';
      let errorMessage = '';
      await loginService
        .checkEmailAuthCode(this.emailAuthCode)
        .then(response => {
          this.$bvModal
            .msgBoxOk(`${popupTitle} 성공`, {
              title: '성공',
              okVariant: 'success',
              centered: true,
            })
            .then(value => {
              this.chkEmailAuth = true;
            });
        })
        .catch(function (error) {
          console.log(error);
          if (error.response) {
            if (error.response.status === 404) {
              errorMessage = '인증번호가 일치하지 않습니다.';
            } else if (error.response.status === 409) {
              errorMessage = "만료된 인증번호입니다. <br>재시도 하시려면 '인증번호 받기'를 눌러주세요.";
            } else {
              errorMessage = `${popupTitle} 서버 오류발생`;
            }
          } else if (error.request) {
            errorMessage = `${popupTitle} 요청 오류발생`;
          } else {
            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
            errorMessage = `${popupTitle} 요청 오류발생`;
            console.log('Error', error.message);
          }
          //console.log(error.config);
          self.$bvModal.msgBoxOk(self.htmlToVNode(errorMessage), {
            title: `${popupTitle} 오류발생`,
            okVariant: 'danger',
            centered: true,
          });
          self.chkEmailAuth = false;
        });
    },
    async resetEmail() {
      this.chkEmailAuth = false;
      this.sendEmailAuth = false;
      this.emailAuthCode = '';
      this.userEmail = '';
    },
    async sendEmailAuthCode() {
      if (this.userEmail === '') {
        this.$bvModal.msgBoxOk('이메일 입력하세요', {
          title: '이메일주소 입력필요',
          okVariant: 'danger',
          centered: true,
        });
        return;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        this.sendEmailAuth = true;
        loginService
          .sendEmailAuthCode(this.userEmail)
          .then(response => {
            if (response.status == 200) {
              this.$bvModal.msgBoxOk('이메일 발송성공 이메일에서 인증코드를 확인후 입력해주세요', {
                title: '성공',
                okVariant: 'success',
                centered: true,
              });
              this.chkEmailAuth = false;
              this.sendEmailAuth = true;
            } else {
              this.$bvModal
                .msgBoxOk('이메일 발송실패', {
                  title: '오류발생',
                  okVariant: 'danger',
                  centered: true,
                })
                .then(value => {
                  this.chkEmailAuth = false;
                });
            }
          })
          .catch(function (error) {
            console.log(error);
            self.$bvModal.msgBoxOk('이메일 발송실패', {
              title: '오류발생',
              okVariant: 'danger',
              centered: true,
            });
            self.chkEmailAuth = false;
          })
          .finally(() => {
            this.sendingEmail = false;
          });
      }
    },
    async sendMail() {
      let tempUpdate = true;
      let notInput = '';
      if (this.affiliate == '' || this.affiliate == null) {
        tempUpdate = false;
        notInput = '제휴사';
      }
      if (this.manager == '' || this.manager == null) {
        tempUpdate = false;
        notInput = '담당자';
      }
      if (this.userEmail == '' || this.userEmail == null) {
        tempUpdate = false;
        notInput = '이메일';
      }
      if (this.userPhone == '' || this.userPhone == null) {
        tempUpdate = false;
        notInput = '연락처';
      }
      if (this.chkEmailAuth === false) {
        tempUpdate = false;
        notInput = '이메일 인증코드';
      }
      if (!tempUpdate) {
        this.$bvModal.msgBoxOk(`${notInput}를(을) 입력해주세요`, {
          title: '필수 입력 확인',
          okVariant: 'danger',
          centered: true,
        });
        return;
      }
      //파일사이즈 확인
      if (this.attachedFile !== null) {
        const filesize = (this.attachedFile.size / 1024 / 1024).toFixed(4);
        if (filesize > 10) {
          // console.log('100메가 이상');
          this.$bvModal.msgBoxOk('10M 이하의 파일만 첨부 가능합니다.', {
            okVariant: 'danger',
            centered: true,
          });
          this.attachedFile = null;
          return;
        }
      }
      const marketingDto = {
        affiliate: this.affiliate,
        manager: this.manager,
        userEmail: this.userEmail,
        userPhone: this.userPhone,
        userCompany: this.userCompany,
        wantedCompany: this.wantedCompany,
        desc: this.desc,
      };
      console.log(marketingDto);
      const url = `/api/SendMarketingMail`;
      const formData = new FormData();
      formData.append('marketingDto', new Blob([JSON.stringify(marketingDto)], {type: 'application/json'}), 'dto.txt');
      formData.append('file', this.attachedFile);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      await axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(async response => {
          self.$bvModal.msgBoxOk('마케팅 제휴 문의가 접수되었습니다', {
            title: '성공',
            okVariant: 'success',
            centered: true,
          });
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            self.$bvModal.msgBoxOk('스크립트 및 html을 입력할수 없습니다', {
              title: '입력오류',
              okVariant: 'danger',
              okTitle: '확인',
              centered: true,
            });
            return;
          }
          self.$bvModal.msgBoxOk('마케팅문의도중 오류발생', {
            title: '마케팅문의도중 오류발생',
            centered: true,
          });
        });
    },
    removeEventSpaces(e) {
      e.preventDefault();
      const left = e.target.value.substring(0, e.target.selectionStart);
      const right = e.target.value.substring(e.target.selectionEnd, e.target.value.length);
      const pasted = (e.dataTransfer || e.clipboardData).getData('text').replace(/ /g, '');
      e.target.value = left + pasted + right;
    },
  },
};
</script>

<style scoped lang="scss">
.inquiry_button {
  height: 3rem;
  line-height: 3rem;
  background-color: #333333;
  color: #fff;
  text-align: center;
}

.page-content {
  padding-bottom: 120px;
}

.in-content-center {
  margin: 0 auto;
  /*width: 1080px !important;*/
  box-sizing: border-box;
}

.contact-detail .box-gray {
  background-color: #f5f5f5;
}

.contact-detail .txt-top {
  padding: 50px 20px 0px 20px;
  margin-bottom: 50px;
  font-size: 17px;
  text-align: center;
  word-break: keep-all;
}

.table-join-form {
  margin: 0 auto;
  width: 100%;
}

.radio-style {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
}

.input-text,
.textarea {
  margin: 0;
  border-radius: 0;
  box-sizing: border-box;
  resize: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  overflow: auto;
}

.textarea.t-medium {
  padding: 10px 15px;
  font-size: 15px;
  line-height: 1.6;
}

.textarea {
  display: block;
  padding: 14px 15px;
  width: 100%;
  border: 1px solid #c2c2c2;
  font-family: 'Nanum Gothic', sans-serif;
  color: inherit;
  font-size: 14px;
  line-height: 20px;
}

.contact-detail .table-contact th {
  width: 150px;
}

.table-join-form th {
  width: 180px;
  color: #333333;
  font-size: 17px;
}

table th {
  font-weight: normal;
}

.table-join-form .table-footer {
  padding-top: 30px;
}

.table-join-form td {
  font-size: 15px;
}

.table-join-form th,
.table-join-form td {
  font-weight: normal;
  line-height: 40px;
}

.button.b-black {
  background-color: #333333;
  color: #fff;
}

.custom-file {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.custom-file input[type='file'] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.custom-file label {
  position: relative;
  display: block;
  padding-right: 124px;
}

.custom-file .alt-input-file {
  display: block;
  overflow: hidden;
}

.input-text.it-medium {
  padding: 0 15px;
  font-size: 15px;
  height: 40px;
  line-height: 40px;
}

.block {
  display: block !important;
}

.header {
  position: relative;
  width: 100%;

  > img {
    width: 100%;
  }

  .overlay {
    color: White;
    position: absolute;
    text-align: center;
    z-index: 12;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: lighter;
    letter-spacing: -0.1rem;
  }
}

.contentDesc {
  text-align: center;

  .title1 {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }

  .title2 {
    margin-top: 3rem;
  }

  .title3 {
    margin-top: 1rem;
    margin-bottom: 5rem;
  }
}

@media (min-width: 992px) {
  .headerimage {
    width: 100%;
    margin: 0;
    content: url('~@/assets/images/common/Eventinquiry_headerimage.jpg');
  }
  .webTable {
    display: table;
  }
  .mobTable {
    display: none;
  }
  .contact-detail .table-contact {
    width: 60%;
  }
  .table-join-form th,
  .table-join-form td {
    padding-top: 10px;
  }
}

@media (max-width: 992px) {
  .headerimage {
  }
  .header {
    position: relative;
    width: 100%;
    padding-top: 5rem;

    > img {
      width: 100%;
    }

    .overlay {
      padding-top: 5rem;
      color: White;
      position: absolute;
      text-align: center;
      z-index: 12;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: lighter;
      letter-spacing: -0.1rem;
    }
  }
  .webTable {
    display: none;
  }
  .mobTable {
    display: table;
  }
  .contact-detail .table-contact {
    width: 100%;
  }
  .table-join-form th {
    padding-top: 10px;
  }
}
</style>
