<template lang="html">
  <ValidationProvider :rules="rules" v-slot="{errors}" slim>
    <b-form-input
      autocomplete="off"
      :state="state(errors)"
      v-model="propValInternal"
      :readonly="readonly"
      :placeholder="placeholder"
      :formatter="phoneFomatter"
    ></b-form-input>
    <!-- <b-form-invalid-feedback :state="errors.length == 0">필수 입력 필요</b-form-invalid-feedback> -->
  </ValidationProvider>
</template>

<script>
import {phoneFomatter} from '@/util/GeneralUtil';

export default {
  name: 'FormTel',
  props: {
    value: {
      required: true,
      default: null,
      type: String,
    },
    readonly: Boolean,
    placeholder: {
      type: [Number, String],
    },
    required: {
      required: false,
      default: true,
    },

    //- 를 포함한 매칭길이 (휴대폰 010-1234-1234 = 13자리)
    numLength: {
      required: false,
      type: Number,
      default: 13,
    },
  },
  data() {
    return {
      propValInternal: '',
    };
  },
  created() {
    this.propValInternal = this.phoneFomatter(this.value);
  },
  computed: {
    rules() {
      if (this.required) {
        return `required|length:${this.numLength}`;
      }
      return null;
    },
  },
  watch: {
    propValInternal(newVal) {
      console.log('propValInternal', newVal);
      this.$emit('input', newVal);
    },
    value(newVal) {
      console.log('value1', newVal);
      this.propValInternal = this.phoneFomatter(newVal);
      console.log('value2', this.propValInternal);
    },
  },
  methods: {
    phoneFomatter: phoneFomatter,
    state(errors) {
      if (this.required) return errors.length == 0;
      return null;
    },
    // input(value) {
    //   this.propValInternal = value;
    //   // console.log(`input:${value}`);
    //   this.$emit('input', value);
    // },
  },
};
</script>
